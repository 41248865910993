/**
 * Function to play floating music
 */
function playMusic(disabled = false) {
  try {
    const myAudio = document.getElementById('myAudio');
    myAudio.volume = 0.5;

    if (disabled) {
      myAudio.pause();
    } else {
      myAudio.play();
    }
  } catch {
    console.error('FAILED_TO_PLAY_MUSIC');
  }
}

export default playMusic;
