import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useGuestInfo from '@/hooks/useGuestInfo';
import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';

import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';
import locales from './locales';

function WelcomeV4() {
  const { name } = useGuestInfo();
  const lang = useLang();
  const invitation = useInvitation();

  const textAnnouncement = ENABLE_MUSLIM_LANGUAGE
    ? locales.welcomingAnnouncementMuslim[lang]
    : locales.welcomingAnnouncementGeneral[lang];

  const textInvitation = ENABLE_MUSLIM_LANGUAGE
    ? locales.welcomingInvitationMuslim[lang]
    : locales.welcomingInvitationGeneral[lang];

  return (
    <Box
      bgColor="transparent"
      bgSize="cover"
      bgPos="center"
      textAlign="center"
      color="mainColorText"
    >
      <WithAnimation>
        <Text>{locales.dear[lang]}</Text>
        <Text>{name ? `${name}` : locales.guest[lang]}</Text>
      </WithAnimation>

      <WithAnimation>
        <Box marginTop="16px" fontSize="md">
          {ENABLE_MUSLIM_LANGUAGE && <Text>Assalamualaikum Warahmatullahi Wabarakatuh</Text>}
          <Text>{invitation ? textInvitation : textAnnouncement}</Text>
        </Box>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(WelcomeV4);
