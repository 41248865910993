import React from 'react';
import { bool, func } from 'prop-types';
import { Box, Heading, Text } from '@chakra-ui/react';
import { WEDDING_RESEPSI_DRESSCODE } from '@/constants';

function ModalWeddingInfo() {
  return (
    <Box textAlign="center">
      <Heading
        textAlign="center"
        fontWeight="normal"
        size="xl"
        fontFamily="LScript"
        textTransform="lowercase"
        color="secondaryColorText"
        margin="24px 0 12px"
      >
        Dresscode
      </Heading>
      <Text>{WEDDING_RESEPSI_DRESSCODE}</Text>
      <Text fontStyle="italic" fontSize="sm">
        - Please wear your comfortable footwear to enjoy our garden party! <br />- Show your best
        colorful outfit and win special prizes from us!
      </Text>
    </Box>
  );
}

ModalWeddingInfo.propTypes = {
  onClose: func.isRequired,
  visible: bool.isRequired,
};

export default ModalWeddingInfo;
