import React from 'react';

import { string } from 'prop-types';
import { Box, Center, Image, Text } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import CountDown from '@/components/Common/CountDown';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import {
  ASSETS_GRAS_MULTIPLE_BOTTOM,
  ASSETS_GRAS_SINGLE,
  ASSETS_FLOWER_LEFT,
  ASSETS_FLOWER_RIGHT,
  BG_COUNTING_DOWN,
  BG_WELCOME,
} from '@/constants/assets';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';

import txt from './locales';

function CountingDown() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box>
      {/* ////////////////////////////////////////////////// */}
      {/* VIDEO WELCOME */}
      {/* ////////////////////////////////////////////////// */}
      <Box bgColor="transparent" bgPos="center" minHeight="600px">
        <video poster={BG_WELCOME} width="100%" height="100%" autoPlay playsInline muted>
          <source
            src="https://user-images.githubusercontent.com/10141928/260853586-132947ff-1d71-4db3-a33e-f0512bc21f6b.mp4"
            type="video/mp4"
          />
          <img
            src={BG_WELCOME}
            height="100%"
            width="100%"
            title="Your browser does not support the <video> tag"
          />
          Your browser does not support the video tag.
        </video>
      </Box>

      {/* ////////////////////////////////////////////////// */}
      {/* COUNTING DOWN SECTION */}
      {/* ////////////////////////////////////////////////// */}
      <Box
        height="100vh"
        maxHeight="700px"
        bgColor="bgPrimary"
        bgImage={`url('${BG_COUNTING_DOWN}')`}
        bgSize="cover"
        bgPos="center"
        position="relative"
        overflow="hidden"
      >
        <WithAnimation delay={800} right>
          <Image
            className="animation__flower-secondary"
            src={ASSETS_FLOWER_RIGHT}
            width="160px"
            pos="absolute"
            right="-50px"
            top="100px"
          />
        </WithAnimation>
        <WithAnimation delay={900} left>
          <Image
            className="animation__flower-secondary"
            src={ASSETS_FLOWER_LEFT}
            pos="absolute"
            width="120px"
            left="-20px"
            top="200px"
          />
        </WithAnimation>
        <Box height="100%" bgColor="bgOpacity" paddingTop="188px">
          <WithAnimation>
            <Text textAlign="center" fontFamily="LScript" color="secondaryColorText" fontSize="2xl">
              Counting <br />
              Days
            </Text>
          </WithAnimation>
          <Box marginTop="16px" width="100%" position="absolute">
            <CountDown lang={lang} />
            {isInvitation && (
              <WithAnimation>
                <Center>
                  <AddToCalendar
                    text={txt.reminder[lang]}
                    {...BUTTON_PADDING_PROPS}
                    fontWeight="normal"
                  />
                </Center>
              </WithAnimation>
            )}
          </Box>
        </Box>
        <Box bottom="0" width="100%" position="absolute">
          <Image
            className="animation__flower"
            width="auto"
            height="120px"
            src={ASSETS_GRAS_SINGLE}
            zIndex={2}
            position="absolute"
            bottom="-20px"
            left="0"
          />
          <Image
            className="animation__flower"
            width="auto"
            height="120px"
            src={ASSETS_GRAS_SINGLE}
            zIndex={2}
            position="absolute"
            right="100px"
            bottom="-20px"
          />
          <Image
            className="animation__flower"
            width="auto"
            height="120px"
            src={ASSETS_GRAS_SINGLE}
            zIndex={2}
            position="absolute"
            bottom="-20px"
            right="-34px"
          />
          <Image pos="relative" src={ASSETS_GRAS_MULTIPLE_BOTTOM} zIndex={3} />
        </Box>
      </Box>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
