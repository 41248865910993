import React from 'react';

import { object, string } from 'prop-types';
import { Box, Center, Heading, Text, AspectRatio, Image } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';

import { BORDER_COLOR, BUTTON_PADDING_PROPS } from '@/constants/colors';
import {
  ASSETS_PROFILE_OUTER,
  ASSETS_PROFILE_BOTTOM,
  ASSETS_FLOWER_RIGHT,
} from '@/constants/assets';

function DetailCouple(props) {
  const {
    imgUrl,
    instagramId,
    parentName,
    fullName,
    shortName,
    shortNameProps,
    instagramIdProps,
    fullNameProps,
    imgUrlProps,
    parentNameProps,
    flowerProps,
    customProps,
    ...rest
  } = props;

  return (
    <Box pos="relative" {...rest}>
      <WithAnimation>
        <Box pos="relative">
          <Center pos="absolute" height={customProps.height} width="100%">
            <Image
              pos="absolute"
              src={ASSETS_PROFILE_OUTER}
              height={customProps.height}
              zIndex="3"
            />
          </Center>
          <Center>
            <AspectRatio width="100%" maxW={customProps.widthRatio} ratio={3 / 4}>
              <Image
                zIndez="2"
                src={imgUrl}
                objectPosition="center"
                margin="0 auto"
                borderRadius="50%"
                border="4px solid"
                borderColor={BORDER_COLOR}
                {...imgUrlProps}
              />
            </AspectRatio>
          </Center>
          <Center width="100%">
            <Image
              pos="absolute"
              zIndex="5"
              src={ASSETS_FLOWER_RIGHT}
              height="180px"
              top="80px"
              {...flowerProps}
            />
          </Center>
        </Box>
      </WithAnimation>
      {customProps.hideFlowerBottom ? null : (
        <WithAnimation>
          <Image
            margin="0 auto"
            marginTop="-32px"
            pos="relative"
            zIndex="4"
            src={ASSETS_PROFILE_BOTTOM}
            width="100%"
            {...customProps.profileBottomProps}
            maxWidth={customProps.profileWidth}
          />
        </WithAnimation>
      )}
      <Box padding="0 24px 24px 24px" pos="relative" zIndex="5">
        <WithAnimation>
          <Heading
            marginTop="-42px"
            color="secondaryColorText"
            textAlign="center"
            fontSize="3xl"
            fontWeight="normal"
            fontFamily="LScript"
            {...shortNameProps}
            dangerouslySetInnerHTML={{ __html: shortName }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text
            color="secondaryColorText"
            fontSize="xl"
            textAlign="center"
            margin="16px 0 16px 0"
            fontFamily="body"
            {...fullNameProps}
          >
            {fullName}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text
            color="mainColorText"
            textAlign="center"
            fontSize="md"
            fontFamily="body"
            dangerouslySetInnerHTML={{ __html: parentName }}
            {...parentNameProps}
          />
        </WithAnimation>
        {instagramId && (
          <Center marginTop="24px">
            <WithAnimation>
              <InstagramButton
                id={instagramId}
                text={instagramId}
                color="white"
                onlyIcon={false}
                size="md"
                {...BUTTON_PADDING_PROPS}
                {...instagramIdProps}
                fontSize="md"
              />
            </WithAnimation>
          </Center>
        )}
      </Box>
    </Box>
  );
}

DetailCouple.propTypes = {
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  parentName: string.isRequired,
  fullName: string.isRequired,
  shortName: string.isRequired,
  shortNameProps: object,
  instagramIdProps: object,
  fullNameProps: object,
  imgUrlProps: object,
  parentNameProps: object,
  flowerProps: object,
  customProps: object,
};

DetailCouple.defaultProps = {
  shortNameProps: {},
  instagramIdProps: {},
  fullNameProps: {},
  imgUrlProps: {},
  parentNameProps: {},
  flowerProps: {},
  customProps: {
    height: '250px',
    widthRatio: '180px',
    profileWidth: `300px`,
    profileBottomProps: {},
  },
};

export default DetailCouple;
