import React from 'react';

import { Box, Center, Button, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import LazyImage from '@/components/Common/LazyImage';
import useLang from '@hooks/useLang';

import { IG_FILTER_URL } from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { BG_INSTAGRAM_FILTER } from '@/constants/assets';

import txt from './locales';

function InstagramFilter() {
  const lang = useLang();

  return (
    <Box padding="0 0 42px 0" pos="relative">
      {/* IMAGE CENTER */}
      <Box>
        <WithAnimation>
          <Heading
            textAlign="center"
            size="xl"
            fontWeight="normal"
            marginTop="32px"
            marginBottom="16px"
            color="secondaryColorText"
            fontFamily="CustomFont"
          >
            wedding filter
          </Heading>
        </WithAnimation>
        <WithAnimation>
          <Text
            padding="0"
            marginBottom="32px"
            fontSize="md"
            textAlign="center"
            color="mainColorText"
          >
            {txt.subTitle[lang]}
          </Text>
        </WithAnimation>
      </Box>
      <WithAnimation>
        <LazyImage
          borderRadius="16px"
          src={BG_INSTAGRAM_FILTER}
          width="80%"
          maxW="300px"
          height="auto"
          margin="0 auto"
          marginTop="16px"
        />
      </WithAnimation>
      <Box padding="0 32px">
        {/* Button Section */}
        <WithAnimation>
          <Center>
            <a href={IG_FILTER_URL} target="_blank" rel="noreferrer">
              <Button
                size="sm"
                margin="16px 0 32px 0"
                letterSpacing="1px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
              >
                {txt.textButton[lang]}
              </Button>
            </a>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(InstagramFilter);
