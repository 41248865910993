import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Button, useDisclosure, Text, Image, AspectRatio } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import WithAnimation from '@/components/Common/WithAnimation';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import {
  ASSETS_BG_PATTERN_2,
  ASSETS_FLOWER_LEFT_2,
  ASSETS_WEDDING_BOTTOM,
  ASSETS_WEDDING_RIGHT,
  IMG_GALLERY_1,
} from '@/constants/assets';

import txt from './locales';
import Content from './Content';

function PhotoSection() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();

  return (
    <WithAnimation>
      <Box
        marginTop="52px"
        padding="42px 0"
        bgSize="cover"
        bgColor="bgSecondary"
        bgImage={`url('${ASSETS_BG_PATTERN_2}')`}
        pos="relative"
      >
        <Box pos="absolute" left="-40px" top="0" zIndex="10">
          <Image src={ASSETS_WEDDING_RIGHT} height="200px" transform="rotate(180deg)" zIndex="4" />
        </Box>
        <Center>
          <Image
            src={ASSETS_WEDDING_BOTTOM}
            height="100px"
            pos="absolute"
            top="-32px"
            zIndex="11"
            transform="rotate(180deg)"
          />
        </Center>
        <Box zIndex="10" pos="absolute" top="0" right="-40px">
          <Image src={ASSETS_FLOWER_LEFT_2} height="200px" transform="rotate(180deg)" zIndex="4" />
        </Box>
        {/* IMAGE CENTER */}
        <Box paddingTop="16px" margin="16px 0 0 0">
          <Center>
            <AspectRatio width="100%" maxW="250px" ratio={3 / 4}>
              <Image
                zIndez="2"
                src={IMG_GALLERY_1}
                objectPosition="center"
                margin="0 auto"
                borderRadius="50%"
              />
            </AspectRatio>
          </Center>
        </Box>
        <Box padding="4px 32px">
          <WithAnimation>
            <Text margin="32px 0 16px 0" textAlign="center" color="mainColorText" fontSize="md">
              {`“If you believe, you will receive whatever you ask for in prayer.”`}
              <br />
              (Matthew 21:22)
            </Text>
          </WithAnimation>
          {/* Button Section */}
          <WithAnimation>
            <Center>
              <Button
                size="sm"
                margin="16px 0 32px 0"
                letterSpacing="1px"
                onClick={() => onOpen()}
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
              >
                {txt.button[lang]}
              </Button>
            </Center>
          </WithAnimation>
        </Box>
        {/* IMAGE BOTTOM */}
        <Image
          src={ASSETS_FLOWER_LEFT_2}
          left="-40px"
          height="200px"
          pos="absolute"
          bottom="0"
          zIndex="4"
        />
        <Center>
          <Image
            src={ASSETS_WEDDING_BOTTOM}
            height="100px"
            pos="absolute"
            bottom="-24px"
            zIndex="5"
          />
        </Center>
        <Image
          src={ASSETS_WEDDING_RIGHT}
          height="200px"
          pos="absolute"
          right="-40px"
          bottom="0"
          zIndex="4"
        />
        {/* Content gallery */}
        <Content lang={lang} isOpen={isOpen} onClose={onClose} />
      </Box>
    </WithAnimation>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);
