import React from 'react';
import { string } from 'prop-types';

import SinglePhoto from './SinglePhoto';

/**
 * Render couple info
 * @param {*} props
 * @returns
 */
function CoupleInfo({ ...rest }) {
  return <SinglePhoto {...rest} />;
}

CoupleInfo.propTypes = {
  lang: string,
};

export default CoupleInfo;
