import React from 'react';
import { string } from 'prop-types';
import { Box, Text, Image, Center } from '@chakra-ui/react';

import QRCard from '@components/Common/QRCard/Lazy';
import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import ModalWeddingInfo from './ModalWeddingInfo';

import useGuestInfo from '@hooks/useGuestInfo';
import useShiftTime from '@hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@hooks/useLang';

import {
  ASSETS_WEDDING_GATE,
  ASSETS_WEDDING_BOTTOM,
  ASSETS_WEDDING_RIGHT,
  ASSETS_FLOWER_RIGHT_2,
  ASSETS_WEDDING_BOTTOM_2,
  ASSETS_FLOWER_RIGHT,
} from '@/constants/assets';
import { ENABLE_MUSLIM_LANGUAGE, ENABLE_QR_INVITATION } from '@/constants/feature-flags';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  GOOGLE_MAPS_RESEPSI,
} from '@/constants';

import { ID_QRCODE } from '@/constants/identifier';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import txt from './locales';
import { Fragment } from 'react/cjs/react.production.min';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const shiftTime = useShiftTime();
  const lang = useLang();
  const { code } = useGuestInfo();

  return (
    <WithAnimation>
      <Fragment>
        <Box padding="32px 16px" width="100%" bgColor="transparent" pos="relative" {...rest}>
          {/* ////////////////////////////////////////////////// */}
          {/* AKAD SECTION */}
          {/* ////////////////////////////////////////////////// */}
          <Center>
            <Box
              bgImage={`url('${ASSETS_WEDDING_GATE}')`}
              bgSize="contain"
              bgRepeat="no-repeat"
              height="420px"
              w="400px"
              bgPos="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              pos="relative"
            >
              <Image
                src={ASSETS_WEDDING_RIGHT}
                left="24px"
                height="200px"
                pos="absolute"
                top="24px"
                zIndex="4"
                transform="rotate(180deg)"
              />
              <Image
                src={ASSETS_FLOWER_RIGHT_2}
                right="12px"
                height="200px"
                pos="absolute"
                top="100px"
                zIndex="4"
              />
              <WithAnimation>
                <Box maxW="190px" textAlign="center" pos="relative">
                  <Text
                    color="secondaryColorText"
                    fontFamily="CustomFont"
                    fontSize="2xl"
                    dangerouslySetInnerHTML={{
                      __html: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
                    }}
                  />
                  {isInvitation ? null : <Text margin="8px 0">{txt.detailAkad[lang]}</Text>}
                  <Text fontWeight={isInvitation ? 'normal' : 'bold'}>{WEDDING_AKAD_FULLDATE}</Text>
                  <Text>{WEDDING_AKAD_TIME}</Text>
                  {isInvitation && (
                    <WithAnimation>
                      <Box marginTop="16px">
                        <Text fontSize="md" color="alternativeColorText">
                          {WEDDING_AKAD_LOC_NAME}
                        </Text>
                        <Text>{WEDDING_AKAD_LOC_ROAD}</Text>
                      </Box>
                    </WithAnimation>
                  )}
                </Box>
              </WithAnimation>
              <Image
                src={ASSETS_WEDDING_BOTTOM_2}
                height="200px"
                pos="absolute"
                bottom={isInvitation ? '-70px' : '-90px'}
                zIndex="4"
              />
              {isInvitation && (
                <Image
                  src={ASSETS_FLOWER_RIGHT}
                  height="200px"
                  pos="absolute"
                  bottom="-90px"
                  left="-16px"
                  zIndex="2"
                  transform="rotate(180deg)"
                />
              )}
              {isInvitation && (
                <Image
                  src={ASSETS_FLOWER_RIGHT}
                  height="200px"
                  pos="absolute"
                  bottom="-90px"
                  right="-16px"
                  zIndex="2"
                  transform="rotate(180deg)"
                />
              )}
            </Box>
          </Center>

          {/* ////////////////////////////////////////////////// */}
          {/* RESEPSI SECTION */}
          {/* ////////////////////////////////////////////////// */}
          {isInvitation && (
            <Center marginTop="-68px">
              <Box
                bgImage={`url('${ASSETS_WEDDING_GATE}')`}
                bgSize="contain"
                bgRepeat="no-repeat"
                height="420px"
                w="400px"
                bgPos="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                pos="relative"
              >
                <WithAnimation>
                  <Box maxW="190px" textAlign="center" pos="relative">
                    <Text
                      color="secondaryColorText"
                      fontFamily="CustomFont"
                      fontSize="2xl"
                      marginTop="42px"
                      dangerouslySetInnerHTML={{ __html: txt.reception[lang] }}
                    />
                    <Text>{WEDDING_RESEPSI_FULLDATE}</Text>
                    <Text>{shiftTime}</Text>
                    <WithAnimation>
                      <Box marginTop="16px">
                        <Text fontSize="md" color="alternativeColorText">
                          {WEDDING_RESEPSI_LOC_NAME}
                        </Text>
                        <Text>{WEDDING_RESEPSI_LOC_ROAD}</Text>
                      </Box>
                    </WithAnimation>
                    <WithAnimation>
                      <ViewLocationButton
                        marginTop="16px"
                        text={txt.seeLocation[lang]}
                        href={GOOGLE_MAPS_RESEPSI}
                        {...BUTTON_PADDING_PROPS}
                        size="sm"
                        fontSize="md"
                      />
                    </WithAnimation>
                  </Box>
                </WithAnimation>
                <Image src={ASSETS_WEDDING_BOTTOM} pos="absolute" bottom="-40px" zIndex="4" />
              </Box>
            </Center>
          )}
        </Box>
        {isInvitation && (
          <WithAnimation right>
            <ModalWeddingInfo />
          </WithAnimation>
        )}
        {/* @TODO: QRCODE IMPLEMENTATION */}
        {code && ENABLE_QR_INVITATION && (
          <div name={ID_QRCODE}>
            <WithAnimation>
              <Box padding="0 32px">
                <QRCard marginTop="32px" />
              </Box>
            </WithAnimation>
          </div>
        )}
        <Box height="42px" />
      </Fragment>
    </WithAnimation>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);
