import React from 'react';
import { Box } from '@chakra-ui/react';

import DetailCouple from './DetailCard';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
  GIRL_NAME_SHORT,
  BOY_NAME_SHORT,
} from '@/constants';
import { IMG_GIRL, IMG_MAN } from '@/constants/assets';

const DetailGroom = () => {
  return (
    <DetailCouple
      instagramId={IG_BOY}
      fullName={BOY_NAME}
      shortName={BOY_NAME_SHORT}
      parentName={BOY_PARENT_NAME}
      imgUrl={IMG_MAN}
      flowerProps={{ marginRight: '-140px' }}
    />
  );
};

const DetailBride = () => {
  return (
    <DetailCouple
      instagramId={IG_GIRL}
      fullName={GIRL_NAME}
      shortName={GIRL_NAME_SHORT}
      parentName={GIRL_PARENT_NAME}
      imgUrl={IMG_GIRL}
      flowerProps={{ marginRight: '-140px' }}
    />
  );
};

function SinglePhoto({ ...rest }) {
  return (
    <Box padding="32px 0" position="relative" bgColor="transparent" {...rest}>
      <Box padding="16px">
        {IS_BOY_FIRST ? <DetailGroom /> : <DetailBride />}
        <Box height="24px" />
        {IS_BOY_FIRST ? <DetailBride /> : <DetailGroom />}
      </Box>
    </Box>
  );
}

export default SinglePhoto;
