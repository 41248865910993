import React, { useState, useEffect } from 'react';

import { Box, Center, Heading, Text, Image } from '@chakra-ui/react';
import { func, bool } from 'prop-types';

import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';
import useLang from '@hooks/useLang';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';

import { BG_COVER, ASSETS_GRAS_MULTIPLE_BOTTOM, ASSETS_GRAS_SINGLE } from '@/constants/assets';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { THE_BRIDE, BRIDE_HASHTAG } from '@/constants';

import txt from './locales';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isInvitation = useInvitation();
  const lang = useLang();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box
      minHeight="100vh"
      bgColor="bgPrimary"
      bgImage={`url('${BG_COVER}')`}
      bgSize="cover"
      bgPos="center"
      position="relative"
      overflow="hidden"
    >
      <Box height="100vh" bgColor="bgOpacity">
        <Center width="100%" zIndex="2" height="90vh">
          <Box width="85%" position="relative" height="100%">
            {showTitle && (
              <Box
                color="mainColorText"
                fontSize="10px"
                width="100%"
                maxW="500px"
                padding="24px"
                bgSize="cover"
                borderRadius="16px"
                paddingTop="166px"
              >
                <Box textAlign="center" color="mainColorText">
                  <WithAnimation>
                    <Heading
                      margin="0"
                      size="2xl"
                      zIndex="99"
                      fontFamily="CustomFont"
                      color="white"
                      dangerouslySetInnerHTML={{
                        __html: THE_BRIDE.toLowerCase().replace('&', '& <br />'),
                      }}
                    />
                  </WithAnimation>
                  <WithAnimation>
                    <Text color="white" fontSize="lg">
                      {BRIDE_HASHTAG}
                    </Text>
                  </WithAnimation>
                </Box>
              </Box>
            )}
          </Box>
        </Center>
        {showTitle && (
          <Box pos="absolute" bottom="160px" width="100%">
            <WithAnimation>
              <ScrollToDown
                loaded={loaded}
                loading={loading}
                marginTop="8px"
                text={isInvitation ? txt.invitation[lang] : txt.announcement[lang]}
                zIndex="99"
                {...BUTTON_PADDING_PROPS}
                size="sm"
                boxShadow="md"
                onClick={handleClickDetail}
              />
            </WithAnimation>
          </Box>
        )}
      </Box>
      <Box bottom="0" width="100%" position="absolute">
        <Image
          className="animation__flower"
          width="auto"
          height="120px"
          src={ASSETS_GRAS_SINGLE}
          zIndex={2}
          position="absolute"
          bottom="-20px"
          left="0"
        />
        <Image
          className="animation__flower"
          width="auto"
          height="120px"
          src={ASSETS_GRAS_SINGLE}
          zIndex={2}
          position="absolute"
          right="100px"
          bottom="-20px"
        />
        <Image
          className="animation__flower"
          width="auto"
          height="120px"
          src={ASSETS_GRAS_SINGLE}
          zIndex={2}
          position="absolute"
          bottom="-20px"
          right="-34px"
        />
        <Image pos="relative" src={ASSETS_GRAS_MULTIPLE_BOTTOM} zIndex={3} />
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
