import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';

function CountItem({ number, text }) {
  return (
    <Box
      width="74px"
      height="90px"
      bgColor="rgb(242 234 217 / 55%)"
      textAlign="center"
      color="secondaryColorText"
      borderRadius="16px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Text fontSize="3xl" fontFamily="body">
          {number}
        </Text>
        <Text fontSize="sm" marginTop="-6px">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
